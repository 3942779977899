const permissions = Object.freeze({
  USUARIOS_BASICOS: "9A961524-284F-4A31-BD55-6340A9CA49E0",
  USUARIOS_AVANZADOS: "6E08BFA2-89CE-478E-BC7E-F854A7E3D7BC",
  DATOS_ACCESO: "9E5E786A-B24D-49A1-9E83-7AD70702E8BB",
  CONEXIONES_RDP: "CF624F00-BE93-471E-88BC-BBEC1BDD51DD",
  //ABM Usuario basicos
  NUEVO_USER_BASICO: "A2C35D8E-768F-4E17-9DF7-E85413F5D992",
  EDIT_USER_BASICO: "AA5226F0-A77D-46B8-8566-644DA0F57E86",
  DELETE_USER_BASICO: "D3EC0A6A-1B80-461B-AB6A-FB2EDBD11FEE",
  //ABM Usuario Avanzado
  NUEVO_USER_AVANZADO: "4B03F82B-873C-4A9D-86D4-44B8102C743C",
  EDIT_USER_AVANZADO: "2AE01F4E-5CDD-4B2E-B945-BE5384641257",
  DELETE_USER_AVANZADO: "8C6D4036-CD3C-4CF1-A2D2-EEA7DD5B65F3",
  //ABM Datos acceso
  NUEVO_DATO_ACCESO: "F753E18F-4AFC-46D9-AC83-CE1EEC259DE7",
  EDIT_DATOS_ACCESO: "48C7F993-0F3B-460F-BED3-987F7D50BB80",
  DELETE_DATOS_ACCESO: "8726B4CD-24F3-4B1B-8488-3B081EC632D5",
  //ABM conexiones rdp
  NUEVA_CONEXION_RDP: "7A3D4EB3-170B-40E2-9850-D1FC95A47EE8",
  EDIT_CONEXION_RDP: "988C15DF-E9AB-41D0-BC3B-D15C2835564D",
  DELETE_CONEXION_RDP: "A8A1EC83-296C-4568-A8CB-6C1774101510",
  CONECTAR_RDP: "10E16395-F437-4E9C-AEC4-48816A903127",
  VER_CONEXION_RDP: "32506903-F6AC-464C-A1EE-A886574EC298",
  //ABM FECHA CORTE
  NUEVA_FECHA_CORTE: "7093F6C8-278E-4183-846D-94FF2E4F7A2E",
  DELETE_FECHA_CORTE: "C9B7E915-8D45-4FAB-B78C-B1EDF6513E9F",
  //ABM FERIADOS
  NUEVO_FERIADO: "BAD371BD-0528-4B99-B831-6B4B124784D7",
  EDIT_FERIADO: "8280406E-D71C-41A8-9378-EBA1DA76278C",
  DELETE_FERIADO: "E2185414-A88C-419B-AFF4-FE077EA8FE20"
});
export default permissions;
