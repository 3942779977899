<template>
  <v-app>
    <IdleDialog v-if="openDialog" :openDialog.sync="openDialog" />
    <nav-bar />

    <v-main>
      <router-view />
      <snack-bar />
    </v-main>

    <foot-bar />
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FootBar from "./components/FootBar.vue";
import SnackBar from "@/components/layout/SnackBar";
import IdleDialog from "@/components/shared/IdleDialog";

export default {
  name: "App",

  components: {
    NavBar,
    FootBar,
    SnackBar,
    IdleDialog,
  },

  data() {
    return {
      openDialog: false,
    };
  },
  onIdle() {
    if (this.$router.currentRoute.name === "FormLogin") return;
    this.openDialog = true;
  },

  created() {},

  methods: {},
};
</script>
