import axios from "../../../axios/axios-api";
import getAPIUrl from "../../../utils/helpers/getAPIUrl.js";

const actions = {
  async fetchDatosAdicionalesClientes(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/FetchDatosAdicionales`,
      payload
    );
    return response.data.data;
  },
  async getTipoUsuarios() {
    const response = await axios.get("api/Taxonomy/GetTipoUsuarios");
    return response.data.data;
  },
  async fetchUsuariosPorCliente(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/FetchUsersPorCliente`,
      payload
    );
    return response.data.data;
  },
  async getUsers(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/McnAdm/GetUsuarios/${payload.clienteId}/${payload.verUsuariosBasicos}`
    );
    return response.data.data;
  },
  async deleteUsuario(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.delete(
      `${h}/api/McnAdm/DeleteUserPorCliente/${payload}`
    );
    return response;
  },
  async getDatosAcceso(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/McnAdm/FetchDatosAcceso/${payload}`
    );
    return response.data.data;
  },
  async saveAccesoDatos(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/SaveDatosAcceso`,
      payload
    );
    return response;
  },
  async deleteAccesoDatos(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.delete(
      `${h}/api/McnAdm/DeleteDatosAcceso/${payload}`
    );
    return response;
  },
  async getClientesAxios() {
    const h = await getAPIUrl();
    const response = await axios.get(`${h}/api/McnAdm`);
    return response.data.data;
  },

  async postNuevaFechaCorte(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(`${h}/api/McnAdm/FechaCorte`, payload);
    return response;
  },
  async saveUsuarios(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/SaveTipoUsuarios`,
      payload
    );
    return response;
  },
  async deleteNuevaFechaCorte(context, id) {
    const h = await getAPIUrl();
    const response = await axios.delete(`${h}/api/McnAdm/FechaCorte/${id}`);
    return response;
  },
  async getConexion(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/McnAdm/GetConexionRdp/${payload}`
    );
    return response.data.data;
  },
  async saveConexion(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/SaveConnection`,
      payload
    );
    return response;
  },
  async deleteConexion(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.delete(
      `${h}/api/McnAdm/DeleteDatosConec/${payload}`
    );
    return response;
  },
  setAlert(context, alert) {
    context.commit("setAlertMessage", alert);
  },
  async fetchFechasCortePorCliente(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/GetFechasCortesClientes`,
      payload
    );
    return response;
  },
  async getClienteFecha(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/McnAdm/GetClientesPorFechaCorte/${payload.fechaCorte}`
    );
    return response;
  },
  async getScriptbyFecha(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/McnAdm/GetScriptPorFechaCorte/${payload}`
    );
    return response.data.data;
  },
  async getDetalleEjecucionXFechaCorte(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/McnAdm/GetDetalleEjecucionXFechaCorte/${payload}`
    );
    return response.data.data;
  },
  async deleteEjecucionPendienteXScript(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.delete(
      `${h}/api/McnAdm/DeleteEjecucionesPendientesXScript/${payload}`
    );
    return response;
  },
  async getScriptXid(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/GetScriptById/${payload}`
    );
    return response.data.data;
  },
  async deleteFechaCorte(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/DeleteFechaCorte`,
      payload
    );
    return response;
  },
  async getMensajeInfoNewFecha() {
    const response = await axios.get("api/McnAdm/mensaje-fecha-corte");
    return response.data.data;
  },
  async postFechaCorteGral(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/SaveFechaCorteGral`,
      payload
    );
    return response;
  },
  async fetchFeriados() {
    const response = await axios.get("api/McnAdm/get-feriados");
    return response.data.data;
  },
  async postFeriados(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(`${h}/api/McnAdm/save-feriados`, payload);
    return response;
  },
  async deleteFeriados(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.delete(
      `${h}/api/McnAdm/DeleteFeriados/${payload}`
    );
    return response;
  },
  async getAllClientData(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/Clientes/GetAllClientData/${payload.clienteId}/${payload.sistemaId}`
    );
    return response.data.data;
  },
  async getSitiosbyCliente(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/McnAdm/SitiosSistema/${payload}`
    );
    return response.data.data;
  },
  async deleteSitio(context, id) {
    const h = await getAPIUrl();
    const response = await axios.delete(`${h}/api/McnAdm/DeleteSitio/${id}`);
    return response;
  },
  async saveSitio(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/SaveSitio`,
      payload
    );
    return response;
  },
  async sitiosTaxonomy(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(`${h}/api/McnAdm/GetSitiosSistema/${payload}`);
    return response.data.data;
  },
  async getServidoresbyCliente(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/McnAdm/ServidoresTipo/${payload}`
    );
    return response.data.data;
  },
  async saveServidor(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/McnAdm/SaveServidor`,
      payload
    );
    return response;
  },
  async deleteServidor(context, id) {
    const h = await getAPIUrl();
    const response = await axios.delete(`${h}/api/McnAdm/DeleteServidor/${id}`);
    return response;
  },
  async servidoresTaxonomy() {
    const response = await axios.get("api/Taxonomy/GetServidoresTipo");
    return response.data.data;
  },
  async sistemasTaxonomy() {
    const response = await axios.get("api/Taxonomy/GetSistemas");
    return response.data.data;
  },
};

export default actions;
