import Vue from "vue";
import VueRouter from "vue-router";
import webSiteOptions from "../utils/enums/modules/webSiteOptions";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { requiresAuth: true },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    name: "FormLogin",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      const token = localStorage.getItem("token");
      if (token) {
        next("/");
      }
      next();
    },
    component: () => import("../views/Login.vue"),
  },

  {
    //OPTION
    path: "/licencias-clientes",
    name: "LicenciasClientes",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch(
          "user/updateFrequencyByOptionCode",
          webSiteOptions.LICENCIASYCLIENTES
        )
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/LicenciasClientes.vue"),
  },
  {
    //OPTION
    path: "/envios-datos-clientes",
    name: "EnvioDatosClientes",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch(
          "user/updateFrequencyByOptionCode",
          webSiteOptions.ENVIODATOSCLIENTES
        )
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/EnvioDatosClientes.vue"),
  },
  {
    //OPTION
    path: "/doble-factor-autenticacion",
    name: "DobleFactorAutenticacion",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch(
          "user/updateFrequencyByOptionCode",
          webSiteOptions.DOBLE_FACTOR_AUTENTICACION
        )
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/DobleFactorAutenticacion.vue"),
  },
  {
    //OPTION
    path: "/clientes",
    name: "clientes",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch("user/updateFrequencyByOptionCode", webSiteOptions.CLIENTES)
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/Clientes.vue"),
  },
  {
    //OPTION
    path: "/clientes/usuarios",
    name: "UsersBasicsYAvan",
    meta: { requiresAuth: true },
    component: () => import("../views/UsersBasicsYAvan.vue"),
  },
  {
    //OPTION
    path: "/clientes/ver-todo",
    name: "SeeAllDataClient",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch(
          "user/updateFrequencyByOptionCode",
          webSiteOptions.VER_TODO_CLIENTES
        )
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/SeeAllDataClient.vue"),
  },
  {
    //OPTION
    path: "/clientes/acceso-datos",
    name: "AccesoDatos",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch(
          "user/updateFrequencyByOptionCode",
          webSiteOptions.DATOS_ACCESO
        )
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/AccesoDatos.vue"),
  },
  {
    //OPTION
    path: "/clientes/sitios-sistema",
    name: "SitiosSistemas",
    meta: { requiresAuth: true },
    component: () => import("../views/SitiosSistemas.vue"),
  },
  {
    //OPTION
    path: "/clientes/servidores-sistema",
    name: "ServidoresSistemas",
    meta: { requiresAuth: true },
    component: () => import("../views/ServidoresSistemas.vue"),
  },
  {
    //OPTION
    path: "/feriados",
    name: "Feriados",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch("user/updateFrequencyByOptionCode", webSiteOptions.FERIADO)
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/Feriados.vue"),
  },
  {
    //OPTION
    path: "/clientes/conexiones-rdp",
    name: "ConexionRdp",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch(
          "user/updateFrequencyByOptionCode",
          webSiteOptions.CONEXIONES_RDP
        )
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/ConexionRdp.vue"),
  },
  {
    path: "/permisos",
    name: "Permisos",
    meta: { requiresAuth: true },
    component: () => import("../views/Permisos.vue"),
  },

  {
    //OPTION
    path: "/usuarios",
    name: "usuarios",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch(
          "user/updateFrequencyByOptionCode",
          webSiteOptions.USUARIOSYPERMISOS
        )
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/Usuarios.vue"),
  },
  {
    //OPTION
    path: "/changePassword",
    name: "ChangePassword",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      store
        .dispatch(
          "user/updateFrequencyByOptionCode",
          webSiteOptions.LICENCIASYCLIENTES
        )
        .then(() => {
          next();
        });
      // ingresa por opcion de menu
    },
    component: () => import("../views/ChangePassword.vue"),
  },
  {
    //OPTION
    path: "/licencias-clientes/fechas-corte",
    name: "FechasCorte",
    meta: { requiresAuth: true },
    component: () => import("../views/FechasCorte.vue"),
  },
  {
    //OPTION
    path: "/licencias-clientes/status-ejecucion-automatica",
    name: "StatusEjecucionAutomatica",
    meta: { requiresAuth: true },
    component: () => import("../views/StatusEjecucionAutomatica.vue"),
  },
  {
    //OPTION
    path: "/licencias-clientes/fechas-corte/clientes-fecha-corte",
    name: "ClientesXFechaCorte",
    meta: { requiresAuth: true },
    component: () => import("../views/ClientesXFechaCorte.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
